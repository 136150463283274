<template>
  <div>
      <div class="quan quan1"></div>
        <div class="quan quan2"></div>
        <div class="auto clearfix">
          <div class="left">
            <div class="title-content">
              HIGE-TECH
              <span class="">OF</span><br />
              ENTERPRISE
            </div>
            <div class="intro">
              <h2>Company introduction</h2>
              <p>
                <!-- 深圳市维超智能科技有限公司，是一家专业从事3D产品研发和运营的高科技企业，专注于3D技术创新、软件开发、内容制作以及3D平台的运营。目前已获20余项专利发明。公司共有国内外发明专利100多项，致力于在裸眼3D领域精耕细作。 -->
                Shenzhen Weichao Intelligent Technology Co., Ltd. is a high-tech enterprise specializing in 3D product R &amp; D and operation, focusing on 3D technology innovation, software development, content production and 3D platform operation. At present, it has obtained more than 20 patents. The company has more than 100 invention patents at home and abroad, and is committed to intensive cultivation in the field of naked eye 3D.
              </p>
              <p>
                <!-- 维超智能依托多元化的产业优势，多年来专注于裸眼3D核心技术研发，采用柱镜光栅，构造了端到端的裸眼3D解决方案。目前拥有全面的裸眼3D产品系列，能兼容多种3D格式，可广泛应用于各行业领域，为观看者呈现逼真震撼的立体影像。 -->
  Relying on its diversified industrial advantages, Weichao intelligence has focused on the research and development of naked eye 3D core technology for many years, and has constructed an end-to-end naked eye 3D solution by using cylindrical mirror grating. At present, it has a comprehensive series of naked eye 3D products, which can be compatible with a variety of 3D formats and can be widely used in various industries to present realistic and shocking three-dimensional images for viewers.
              </p>
            </div>
          </div>
          <div class="right">
            <img src="@/assets/img/about/Floor002694_01.jpg" alt="">
          </div>
        </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>

    .quan {
      position: absolute;
      width: 62.5%;
      height: 100%;
      overflow: hidden;
      opacity: 0.3;
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      z-index: -1;
      background: -moz-radial-gradient(
        center,
        ellipse cover,
        rgba(149, 220, 252, 1) 0%,
        rgba(223, 245, 254, 0) 70%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-radial-gradient(
        center,
        ellipse cover,
        rgba(149, 220, 252, 1) 0%,
        rgba(223, 245, 254, 0) 70%,
        rgba(255, 255, 255, 0) 100%
      );
      background: radial-gradient(
        ellipse at center,
        rgba(149, 220, 252, 1) 0%,
        rgba(223, 245, 254, 0) 70%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#95dcfc', endColorstr='#00ffffff',GradientType=1 );
    }
    .quan1 {
      left: -31%;
      top: -45%;
    }
    .quan2 {
      right: 0;
      bottom: -24%;
      left: 50%;
      top: 0;
      position: absolute;
      width: 62.5%;
      height: 100%;
      overflow: hidden;
      opacity: 0.3;
    }
    .auto {
      max-width: 1760px;
      position: relative;
      z-index: 3;
      margin: 0 auto;
      .left {
        background: url("~@/assets/img/about/Floor002694_bg_txt.png") no-repeat
          left top;
        min-height: 320px;
        background-size: auto 81.5%;
        position: relative;
        z-index: 3;
        width: 50%;
        float: left;
        .title-content {
          text-align: right;
          font-family: "DINCond-Bold";
          color: #0068b7;
          font-size: 80px;
          text-transform: uppercase;
          line-height: 100px;
          padding: 4% 0;
          span {
            color: #eff3f9;
            margin-left: -100px;
          }
        }
        .intro {
          color: #333;
          h2 {
            padding-top: 0%;
            padding-bottom: 5%;
            margin: 0;
            font-size: 40px;
            font-weight: normal;
          }
          & > * {
            padding-left: 24%;
          }
          p {
            line-height: 32px;
            position: relative;
            margin: 0;
            &::before {
              position: absolute;
              content: "";
              height: 1px;
              background: #f08311;
              left: 0;
              width: 17%;
              top: 16px;
            }
          }
        }
      }
      .right {
        width: 50%;
        float: right;
        &::after {
          display: block;
          width: 60%;
          height: 100px;
          background: #eff3f9;
          content: "";
          margin-top: 8%;
        }
        img {
          display: block;
          width: 100%;
          height: 300px;
        }
      }
    }
   @media (max-width: 992px) {
      .auto{
        .left{
          width: 90%;
        }
      }
    }
</style>