<template>
  <div class="about">
    <header-img :bgImg="require('@/assets/img/headerImg/Banner002187-ban1_wap.jpg')"></header-img>
    <div class="about-box">
      <!-- <div class="title">
        <div class="title-box">
          <div class="product-title">—关于我们</div>
          <div class="title-flex" ref="activeClick">
            <div data-index="1" @click="activeClick" :class="{ active: this.titleActive == 1 }">
              <span>公司介绍</span>
            </div>
            <div data-index="2" @click="activeClick" :class="{ active: this.titleActive == 2 }">
              <span>荣誉资质</span>
            </div>
          </div>
        </div>
      </div> -->
      <item-contorl :item="item" :titleActive="titleActive" :toRoute="thisRoute"></item-contorl>
      <div class="detail-box">
        <about-company v-show="titleActive==1"></about-company>
        <about-honor v-show="titleActive==2"></about-honor>
      </div>
    </div>
  </div>
</template>
<script>
import headerImg from "@/components/CommonConponent/headerImg.vue";
import ItemContorl from '../components/CommonConponent/itemContorl.vue';
import aboutCompany from "@/components/about/aboutCompany.vue";
import AboutHonor from '../components/about/aboutHonor.vue';

export default {
  components: {
    headerImg,aboutCompany,
    AboutHonor,ItemContorl
  },
  data() {
    return {
      thisRoute:'about',
      titleActive: this.$route.query.id || "1" ,
      item:{
        title:'About',
         list:[{index:'1',detail:'Company Profile'},{index:'2',detail:'Honor'}]
       },
    };
  },
  methods: {
    activeClick(e) {
      this.titleActive = e.target.parentElement.dataset.index || e.target.dataset.index; 
    },
  },
    watch:{
    $route(to,from){
      //监听路由，当路由改变时，改变选项
      this.titleActive = to.query.id
    }
}
};
</script>
<style lang="less" scoped>
.about-box {
  min-height: 300px;

  .detail-box {
    position: relative;
    z-index: 1;
    padding: 6% 0 3% 0;
   min-height: 543px;
    overflow: hidden;
   
  }
}

</style>
<style >
  @media (max-width: 391px) {
.quan{
  display: none;
}
}
</style>