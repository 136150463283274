<template>
  <div class="about-honor bg-cover">
    <div class="honor">
      <el-row  class="honor-box">
        <el-col :xs="24"  :sm="12" :lg="8" >
          <div class="list-container">
            <div class="list-container-img">
              <img
                src="@/assets/img/about/honor/PicList002172-img1.png"
                alt=""
              />
            </div>
            <div class="list-container-p">
                <h5>2016-2018</h5>  
                <p>Weichao Intelligent Branch Factory Patent</p>
                </div>
          </div>
        </el-col>
        <el-col :xs="24"  :sm="12" :lg="8">
          <div class="list-container">
            <div class="list-container-img">
              <img
                src="@/assets/img/about/honor/PicList002172-img3.png"
                alt=""
              />
            </div>
            <div class="list-container-p">
                <h5>2016-2018</h5>
                <p>Weichao Intelligent Branch Factory Patent</p>
                </div>
          </div>
        </el-col>        <el-col :xs="24"  :sm="12" :lg="8">
          <div class="list-container">
            <div class="list-container-img">
              <img
                src="@/assets/img/about/honor/PicList002172-img4.png"
                alt=""
              />
            </div>
            <div class="list-container-p">
                <h5>2016-2018</h5>
                <p>Weichao Intelligent Branch Factory Patent</p>
                </div>
          </div>
        </el-col>        <el-col :xs="24"  :sm="12" :lg="8">
          <div class="list-container">
            <div class="list-container-img">
              <img
                src="@/assets/img/about/honor/PicList002172-img1.png"
                alt=""
              />
            </div>
            <div class="list-container-p">
                <h5>2016-2018</h5>
                <p>Weichao Intelligent Branch Factory Patent</p>
                </div>
          </div>
        </el-col>        <el-col :xs="24"  :sm="12" :lg="8">
          <div class="list-container">
            <div class="list-container-img">
              <img
                src="@/assets/img/about/honor/PicList002172-img2.png"
                alt=""
              />
            </div>
            <div class="list-container-p">
                <h5>2016-2018</h5>
                <p>Weichao Intelligent Branch Factory Patent</p>
                </div>
          </div>
        </el-col>        <el-col :xs="24"  :sm="12" :lg="8">
          <div class="list-container">
            <div class="list-container-img">
              <img
                src="@/assets/img/about/honor/PicList002172-img3.png"
                alt=""
              />
            </div>
            <div class="list-container-p">
                <h5>2016-2018</h5>
                <p>Weichao Intelligent Branch Factory Patent</p>
                </div>
          </div>
        </el-col>    
      </el-row>
      <!-- <new-pagination></new-pagination> -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="2">
        </el-pagination>
    </div>
  </div>
</template>

<script>
import newPagination from '../CommonConponent/newPagination.vue';

export default {
  components: { newPagination },};
</script>

<style lang="less" >
.about-honor {
  background: url("~@/assets/img/about/PicList002172-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .honor {
    margin: 0 auto;
    padding-top: 40px;
    .honor-box {
        max-width: 1400px;
        margin: 0 auto;
      > div {
        height: 200px;
        // margin-bottom: 40px;
        padding: 0 20px 20px 20px;
        margin-bottom: 50px;
        .list-container {
            border: 1px solid #0068b7;
            width: 100%;
            display: flex;
          .list-container-img{
              width: 178px;
              height: 204px;
              margin: 0 10px 0 30px;
              img{
                  width: 178px;
                 height: 205px;
                 transition: all 0.4s;
                 &:hover{
                     transform: scale(1.2);
                     transition: all 0.4s;
                 }
              }
          }
          .list-container-p{
              padding: 50px 0 0 20px;
              overflow: hidden;
            text-align: left;
              h5{
                  font-size: 16px ;
                  font-weight: 600;
     
    overflow: hidden;
    white-space: nowrap;
              }
              p{
                  margin: 10px 0;
    line-height: 22px;
    font-size: 16px;
              }
          }
        }
      }
    }
  }
}
</style>>
